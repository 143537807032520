import { Mixins, Component } from "vue-property-decorator";

import TransactionsMixin from "../../common/mixins/transactions.mixin";

import TransactionsTable from "./components/TransactionsTable/TransactionsTable.vue";

@Component({
  components: {
    TransactionsTable,
  },
})
export default class DesktopTransactions extends Mixins(TransactionsMixin) {
  private onUpdateTableOptions({
    sortBy,
    sortDesc,
  }: {
    sortBy: string[];
    sortDesc: boolean[];
  }) {
    const sort: string[] = [];

    sortBy.forEach((sortField, sortFieldIndex) => {
      const sortFieldDesc = sortDesc[sortFieldIndex];

      switch (sortField) {
        case "date":
          sortField = "createdAt";
          break;

        case "totalTransactionFee":
          sortField = "totalFee";
          break;

        default:
          break;
      }

      sort.push(sortFieldDesc ? `-${sortField}` : sortField);
    });

    this.filter.sort = sort;
  }

  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();
  }
}
