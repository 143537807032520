var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{staticClass:"cards-table",attrs:{"id":_vm.id,"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"footer-props":{
    itemsPerPageOptions: [15, 30, 45],
  },"items-per-page":_vm.syncedItemsPerPage,"page":_vm.syncedPage,"server-items-length":_vm.serverItemsLength,"multi-sort":""},on:{"update:itemsPerPage":function($event){_vm.syncedItemsPerPage=$event},"update:items-per-page":function($event){_vm.syncedItemsPerPage=$event},"update:page":function($event){_vm.syncedPage=$event},"update:options":_vm.onUpdateOptions},scopedSlots:_vm._u([{key:`item.amount`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney({ value: item.amount, currency: item.currency }))+" ")]}},{key:`item.type`,fn:function({ item }){return [_c('div',_vm._g({class:[
        'position-relative d-inline-block cards-table-type pa-2',
        {
          'cursor-pointer':
            (!_vm.appliedSearchByReceptId && item.refunded) ||
            (item.recieptId &&
              _vm.isRefundType(item.typeCode) &&
              !_vm.appliedSearchByReceptId),
        },
      ]},
        (!_vm.appliedSearchByReceptId && item.refunded) ||
        (item.recieptId &&
          _vm.isRefundType(item.typeCode) &&
          !_vm.appliedSearchByReceptId)
          ? {
              click: () => _vm.searchByReceptId(item.recieptId),
            }
          : {}
      ),[_c('v-chip',{class:[
          'rounded-xl',
          {
            'pr-7':
              item.recieptId &&
              _vm.isRefundType(item.typeCode) &&
              !_vm.appliedSearchByReceptId,
          },
        ],style:({
          height: '26px',
          fontSize: '15px',
          border: '1px solid rgba(0, 0, 0, 0.2)',
          pointerEvents: 'none',
        }),attrs:{"color":item.typeColor,"text-color":"white"}},[(item.typeIcon)?_c('v-icon',{attrs:{"size":"16","left":""}},[_vm._v(" "+_vm._s(item.typeIcon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.type)+" ")],1),(item.refunded)?_c('v-chip',{staticClass:"text-uppercase font-weight-medium rounded-md pa-1",style:({
          position: 'absolute',
          bottom: '0',
          right: '0',
          maxWidth: 'none',
          pointerEvents: 'none',
        }),attrs:{"x-small":"","color":"primary"}},[_c('v-icon',{staticClass:"flex-shrink-0",style:({ opacity: 0.5, marginRight: '2px' }),attrs:{"size":"8"}},[_vm._v(" "+_vm._s(_vm.appliedSearchByReceptId ? "$undo" : "$magnifyingGlassB")+" ")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.refunded"))+" ")],1):_vm._e(),(
          item.recieptId &&
          _vm.isRefundType(item.typeCode) &&
          !_vm.appliedSearchByReceptId
        )?_c('v-hover',{attrs:{"close-delay":"150","open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-btn',{class:[
              'cards-table-type__search-button my-auto px-0 rounded-xl overflow-hidden',
              {
                'cards-table-type__search-button_hover': hover,
              },
            ],attrs:{"width":hover ? 'calc(100% - 22px)' : 20,"absolute":"","min-width":"20","height":"20","color":"blue darken-3"}},[_c('span',{staticClass:"cards-table-type__search-button-text pl-1 white--text text-caption-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.search"))+" ")]),_c('v-icon',{staticClass:"ml-auto mr-1",attrs:{"size":"11","color":"white"}},[_vm._v(" $magnifyingGlassB ")])],1)]}}],null,true)}):_vm._e()],1)]}},{key:`item.totalTransactionFee`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatMoney({ value: item.totalTransactionFee, currency: item.currency, }))+" ")]}},{key:`item.date`,fn:function({ item }){return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")])]}},{key:`item.cardNumber`,fn:function({ item }){return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.cardNumber)+" ")])]}},{key:`item.currency`,fn:function({ item }){return [_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.getSymbolCurrency(item.currency))+" ")])]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center text-no-wrap"},[_c('div',{class:[
          'rounded-circle flex-shrink-0 d-flex align-center justify-center mr-2',
          `${_vm.statusTransaction(item.status).color}`,
        ],style:({
          width: '24px',
          height: '24px',
        })},[_c('v-icon',{staticClass:"flex-shrink-0",attrs:{"size":"19","color":"white"}},[_vm._v(" "+_vm._s(_vm.statusTransaction(item.status).icon)+" ")])],1),_c('section',{staticClass:"text-caption-2"},[_vm._v(" "+_vm._s(_vm.statusTransaction(item.status).text)+" "),(_vm.statusTransaction(item.status).label)?_c('aside',{class:[
            'text-caption-3',
            _vm.statusTransaction(item.status).label.color,
          ],style:({ lineHeight: '1.1' })},[_vm._v(" "+_vm._s(_vm.statusTransaction(item.status).label.text)+" ")]):_vm._e()])])]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }