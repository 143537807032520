import { Component, PropSync, Prop, Mixins } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types";
import { Transaction } from "@/types/transaction";

import TransactionMixin from "../../../../common/mixins/transaction.mixin";

@Component
export default class TransactionsTable extends Mixins(TransactionMixin) {
  @Prop({ type: Array, required: true })
  private readonly headers!: DataTableHeader[];
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: Array, required: true }) private readonly items!: Transaction[];
  @PropSync("itemsPerPage", {
    default: 15,
    type: Number,
  })
  private readonly syncedItemsPerPage!: number;
  @PropSync("page", {
    default: 1,
    type: Number,
  })
  private readonly syncedPage!: number;
  @Prop({ type: String }) private readonly id?: string;
  @Prop({ type: Number }) private readonly serverItemsLength?: number;

  private onUpdateOptions<T>(value: T) {
    this.$emit("update:options", value);
  }
}
